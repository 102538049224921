"use strict";

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/**
 * @requires ../vendor/slider-revolution/js/jquery.themepunch.enablelog.js
 * @requires ../vendor/slider-revolution/js/jquery.themepunch.tools.min.js
 * @requires ../vendor/slider-revolution/js/jquery.themepunch.revolution.js
 */
$(document).ready(function () {
    var _$$show$revolution, _$$show$revolution2;

    $('.tp-banner').show().revolution((_$$show$revolution = {
        dottedOverlay: "none",
        delay: 7000,
        // startwidth:1170,
        // startheight:700,
        hideThumbs: 200,

        thumbWidth: 100,
        thumbHeight: 50,
        thumbAmount: 5,

        navigationType: "bullet",
        navigationArrows: "none",
        navigationStyle: "preview2",

        touchenabled: "on",
        onHoverStop: "on",

        swipe_velocity: 0.7,
        swipe_min_touches: 1,
        swipe_max_touches: 1,
        drag_block_vertical: false,

        parallax: "mouse",
        parallaxBgFreeze: "on",
        parallaxLevels: [7, 4, 3, 2, 5, 4, 3, 2, 1, 0],

        keyboardNavigation: "off",

        navigationHAlign: "center",
        navigationVAlign: "bottom",
        navigationHOffset: 0,
        navigationVOffset: 20,

        soloArrowLeftHalign: "left",
        soloArrowLeftValign: "center",
        soloArrowLeftHOffset: 20,
        soloArrowLeftVOffset: 0,

        soloArrowRightHalign: "right",
        soloArrowRightValign: "center",
        soloArrowRightHOffset: 20,
        soloArrowRightVOffset: 0,

        shadow: 0,
        fullWidth: "on",
        autoHeight: "on",
        fullScreen: "on",

        spinner: "spinner4",

        stopLoop: "off",
        stopAfterLoops: -1,
        stopAtSlide: -1,

        shuffle: "off"

    }, _defineProperty(_$$show$revolution, "autoHeight", "off"), _defineProperty(_$$show$revolution, "forceFullWidth", "off"), _defineProperty(_$$show$revolution, "hideThumbsOnMobile", "off"), _defineProperty(_$$show$revolution, "hideNavDelayOnMobile", 1500), _defineProperty(_$$show$revolution, "hideBulletsOnMobile", "off"), _defineProperty(_$$show$revolution, "hideArrowsOnMobile", "off"), _defineProperty(_$$show$revolution, "hideThumbsUnderResolution", 0), _defineProperty(_$$show$revolution, "hideSliderAtLimit", 0), _defineProperty(_$$show$revolution, "hideCaptionAtLimit", 0), _defineProperty(_$$show$revolution, "hideAllCaptionAtLilmit", 0), _defineProperty(_$$show$revolution, "startWithSlide", 0), _defineProperty(_$$show$revolution, "videoJsPath", "slider-revolution/videojs/"), _defineProperty(_$$show$revolution, "fullScreenOffsetContainer", ""), _$$show$revolution));
    $('.tp-banner2').show().revolution((_$$show$revolution2 = {
        dottedOverlay: "none",
        delay: 7000,
        // startwidth:1170,
        // startheight:700,
        hideThumbs: 200,

        thumbWidth: 100,
        thumbHeight: 50,
        thumbAmount: 5,

        navigationType: "bullet",
        navigationArrows: "none",
        navigationStyle: "preview2",

        touchenabled: "on",
        onHoverStop: "on",

        swipe_velocity: 0.7,
        swipe_min_touches: 1,
        swipe_max_touches: 1,
        drag_block_vertical: false,

        parallax: "mouse",
        parallaxBgFreeze: "on",
        parallaxLevels: [7, 4, 3, 2, 5, 4, 3, 2, 1, 0],

        keyboardNavigation: "off",

        navigationHAlign: "center",
        navigationVAlign: "bottom",
        navigationHOffset: 0,
        navigationVOffset: 20,

        soloArrowLeftHalign: "left",
        soloArrowLeftValign: "center",
        soloArrowLeftHOffset: 20,
        soloArrowLeftVOffset: 0,

        soloArrowRightHalign: "right",
        soloArrowRightValign: "center",
        soloArrowRightHOffset: 20,
        soloArrowRightVOffset: 0,

        shadow: 0,
        fullWidth: "on",
        autoHeight: "on",
        fullScreen: "on",

        spinner: "spinner4",

        stopLoop: "off",
        stopAfterLoops: -1,
        stopAtSlide: -1,

        shuffle: "off"

    }, _defineProperty(_$$show$revolution2, "autoHeight", "off"), _defineProperty(_$$show$revolution2, "forceFullWidth", "off"), _defineProperty(_$$show$revolution2, "hideThumbsOnMobile", "off"), _defineProperty(_$$show$revolution2, "hideNavDelayOnMobile", 1500), _defineProperty(_$$show$revolution2, "hideBulletsOnMobile", "off"), _defineProperty(_$$show$revolution2, "hideArrowsOnMobile", "off"), _defineProperty(_$$show$revolution2, "hideThumbsUnderResolution", 0), _defineProperty(_$$show$revolution2, "hideSliderAtLimit", 0), _defineProperty(_$$show$revolution2, "hideCaptionAtLimit", 0), _defineProperty(_$$show$revolution2, "hideAllCaptionAtLilmit", 0), _defineProperty(_$$show$revolution2, "startWithSlide", 0), _defineProperty(_$$show$revolution2, "videoJsPath", "slider-revolution/videojs/"), _defineProperty(_$$show$revolution2, "fullScreenOffsetContainer", ""), _$$show$revolution2));
});